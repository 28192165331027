import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { lazy, Suspense } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const HTMLPreviewNewsLetter = lazy(() =>
	import("./pages/html-preview/html-preview.page")
);
const CrearNewsLetter = lazy(() =>
	import("./pages/crear-newsletter/crear-newsletter.page")
);
const ListarNewsLetter = lazy(() =>
	import("./pages/listar-newsletter/listar-newsletter.page")
);
// const PreviewNewsletter = lazy(() => import("./pages/preview/preview.page"));

function App() {
	return (
		<div className="App">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover
			/>
			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					<Route path="crear" element={<CrearNewsLetter />} />
					<Route path="editar" element={<CrearNewsLetter />}>
						<Route path=":newsletterId" element={<CrearNewsLetter />} />
					</Route>
					<Route path="preview" element={<HTMLPreviewNewsLetter />}>
						<Route path=":newsletterId" element={<HTMLPreviewNewsLetter />} />
					</Route>
					<Route path="*" element={<ListarNewsLetter />} />
				</Routes>
			</Suspense>
		</div>
	);
}

export default App;
